<template>
  <div>
    <section class="seccao header-fixo">
      <Header
        :title="'Area Privada'"
        :breadcrums="breadcrums"
        :cartItems="cartItems"
      />
      <div class="body-ap">
        <div class="altura-auto-ap">
          <div class="row">
            <div class="col-12">
              <h2 class="title-with-strap">{{ "Olá " + user.nome }}</h2>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-12">
              <h3>Conteúdos</h3>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div
              class="jumbotron"
              style="
                background-color: #ffffff;
                border-radius: 20px;
                width: 100%;
              "
            >
              <div
                class="card mt-3"
                v-for="(conteudo, i) in conteudos"
                :key="i"
              >
                <div class="accordion">
                  <span>
                    {{ conteudo.titulo }}
                    <i
                      @click="abrir(i)"
                      :class="conteudo.chevron"
                      class="pointer"
                    ></i>
                  </span>
                </div>
                <div :class="conteudo.open == '1' ? 'show' : 'hide'">
                  <div class="row">
                    <div class="col-12">
                      <div v-if="conteudo.tipo == 'Imagem'" class="text-center">
                        <img
                          class="pointer imagemConteudo"
                          @click="abrirImagem(conteudo.url)"
                          :src="conteudo.url"
                        />
                      </div>
                      <div
                        v-else-if="conteudo.tipo == 'Ficheiro'"
                        class="text-center"
                      >
                        <img
                          class="pointer imagemConteudo"
                          @click="abrirImagem(conteudo.url)"
                          src="https://media.istockphoto.com/vectors/download-icon-isolated-vector-vector-id844294300?k=20&m=844294300&s=612x612&w=0&h=Um3AUbTxB9AlQVK_0ykyr4UO3Yy6rcB0HII6HZbf28M="
                        />
                      </div>
                      <div
                        v-else-if="conteudo.tipo == 'Video'"
                        class="text-center"
                      >
                        <iframe
                          width="560"
                          height="315"
                          :src="conteudo.url"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>

                        <!-- <a
                          @click="openVideo(conteudo.url)"
                          class="btn-video-open links"
                        >
                          <div class="photo-container">
                            <img
                            :src="`https://portal.glintt.com/assets/fotos_catalogo/products/${item.image}`"
                        />
                            <div class="overlay-video">
                              <i class="far fa-play-circle"></i>
                            </div>
                          </div>
                          <h3 class="orange-strap">{{ item.name }}</h3>
                        <p id="paragraph">{{ item.description }}</p>
                        <p>{{item.dt_created}}</p>
                        <a class="button ghost diagonal">Saiba Mais</a>
                        </a> -->
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div class="col-12">
                      {{ conteudo.descricao }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="overlay" :style="'display: ' + videoOpen">
                <div id="icon-close-video" @click="videoClose()">
                  <i class="fas fa-times"></i>
                </div>
                <div id="videoLayer">
                  <video
                    v-if="
                      videoPlaying != '' &&
                      !this.videoPlaying.includes('youtube')
                    "
                    style="max-width: 80vw; z-index: 1000"
                    controls
                    autoplay
                  >
                    <source
                      :src="`assets/fotos_catalogo/products/${videoPlaying}`"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                  <iframe
                    v-else
                    width="100%"
                    style="height: 100%"
                    :src="`${videoPlaying}`"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  >
                  </iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="feed" class="seccao header-fixo">
        <Footer />
      </section>

      <div v-if="!carregado" class="loadingLayer" style="'display: flex'">
        <img src="/assets/imagens/loading.gif" />
        <h3>A carregar...</h3>
      </div>
    </section>
  </div>
</template>

<script
  type="text/javascript"
  src="@/assets/ficheiros/jquery-3.4.1.min.js"
></script>
<script type="text/javascript" src="@/assets/ficheiros/swiper.min.js"></script>

<script>
  import Header from "@/components/Header.vue";
  import Footer from "@/components/Footer.vue";
  import Area from "@/components/Area.vue";
  import api from "../api/api_calls.js";
  export default {
    name: "categoria",
    components: {
      Header,
      Footer,
      Area,
    },
    props: ["backdoor"],
    data() {
      return {
        user: [],
        conteudos: [],
        carregado: false,
        message: "",
        isOpen: false,
        videoOpen: "none",
        videoPlaying: "",
        // ORIGINAL
        imgProd: "/assets/imagens/default.png",
        imgFam: "/assets/imagens/default.png",
        imgImg: "/assets/imagens/default.png",
        category_id: this.$route.params.id,
        menu: {},
        category_data: {},
        feed_families: [],
        feed_products: [],
        breadcrums: {},
        loadingStyle: "flex",
        isLogged: window.sessionStorage.user_level,
        n: 0,
      };
    },
    computed: {
      createSlider: function () {
        let familiesItems =
          this.feed_families != undefined
            ? Object.keys(this.feed_families).length
            : 0;
        let productsItems =
          this.feed_products != undefined
            ? Object.keys(this.feed_products).length
            : 0;
        return familiesItems + productsItems > 5;
      },

      materiaisCarrinho() {
        this.n;
        let temp = JSON.parse(window.sessionStorage.getItem("cart")) || [];

        return temp;
      },
      m() {
        this.backdoor;
        return this.n;
      },
      cartItems() {
        this.n;
        let len = this.materiaisCarrinho.length || 0;

        return len;
      },
    },
    methods: {
      async openVideo(src) {
        this.videoPlaying = src;
        this.videoOpen = "block";

        // this.videoPlaying = "https://www.w3schools.com/html/mov_bbb.mp4"
      },
      async videoClose() {
        this.videoPlaying = "";
        this.videoOpen = "none";
      },
      abrirImagem(imagem) {
        window.open(imagem, "_blank");
      },
      abrir(index) {
        for (let i = 0; i < this.conteudos.length; i++) {
          if (i != index) {
            this.conteudos[i].open = "0";
          } else {
            if (this.conteudos[i].open == "1") {
              this.conteudos[i].open = "0";
            } else {
              this.conteudos[i].open = "1";
            }
          }
        }
      },
      async inicio() {
        var id = window.sessionStorage.getItem("user_id");
        var _this = this;
        if (!id || id == null) {
          this.$router.push("/home");
          return;
        }

        let json = {
          id: id,
        };

        let resultado = await api.call_api_post("user/get/data", json);
        resultado = Object.values(resultado);

        if (resultado.length > 0) {
          this.user = resultado[0];
          let conteudos = await api.call_api_post("conteudos/area/privada");
          this.conteudos = Object.values(conteudos);
          this.conteudos = this.conteudos.filter(
            (x) => x.tipologia.split(",").indexOf(this.user.tipologia) > -1
          );

          for (let i = 0; i < this.conteudos.length; i++) {
            this.conteudos[i].chevron = "fa fa-chevron-down";
          }
        } else {
          this.$router.push("/home");
          return;
        }
        setTimeout(function () {
          _this.carregado = true;
        }, 500);
      },

      call_api_get: async function (url, headers) {
        try {
          var response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          var result = await response.json();
          return result;
        } catch (error) {
          console.error("Error:", error);
          return "Error: " + error;
        }
      },
    },
    created() {},
    watch: {
      "$route.params.id"(newId, oldId) {
        this.category_id = newId;
        this.collect_page_data();
        this.collect_feed_data();
      },
    },
    mounted: function () {
      this.inicio();
    },
  };
</script>

<style src="@/assets/style.css"></style>
<style src="@/assets/produtos.css"></style>
<style src="@/assets/responsive.css"></style>
<style src="@/assets/ficheiros/swiper.min.css"></style>
<style src="@/assets/ficheiros/font-awesome.min.css"></style>

<style scoped>
  .overlay-video i {
    color: #ea8824;
    top: calc(50% - 50px);
    transform: translate(-50%, 0);
    position: absolute;
    font-size: 5vh;
    left: 50%;
    opacity: 0.8;
  }
  .photo-container:hover .overlay-video i {
    opacity: 1;
  }

  .links:focus {
    outline: none;
  }
  .overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 900;
  }

  .btn-video-open:hover {
    cursor: pointer;
  }

  #icon-close-video {
    color: #fff;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    font-size: 25px;
  }

  #icon-close-video:hover {
    cursor: pointer;
  }

  #videoLayer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
    width: 80vw;
    background: #000;
    left: 10vw;
    top: 10vh;
    z-index: 999;
  }
  .imagemConteudo {
    max-width: 30%;
  }
  .show {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 1100ms;
    text-align: justify;
    text-justify: inter-word;
    padding: 5px;
    background-color: #ffffff;
    color: black;
    font-size: 20px;
    font-weight: 300px;
    width: 100% !important;
  }

  .hide {
    visibility: hidden;
    opacity: 0;
    height: 0;
    -moz-transition: height 2s ease;
    -webkit-transition: height 2s ease;
    -o-transition: height 2s ease;
    transition: height 2s ease;
  }

  .accordion {
    background-color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow: hidden;
    font-weight: 400;
    width: 99%;
  }
  i {
    float: right;
    padding-top: 25px;
  }
  .card {
    padding: 15px;
  }
  .body-ap {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .altura-auto-ap {
    padding-top: calc(128px + 8.35vh) !important;
  }
  .swiper-pagination-bullet-active,
  .swiper-pagination-bullet {
    display: none;
  }
  .swipe-menu {
    position: relative;
    width: 100%;
    height: auto !important;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
  .links {
    color: inherit;
    text-decoration: none;
  }

  .video-container {
    display: flex;
    background: #000;
  }

  /* .video-container {
        display: flex;
        justify-content: center;
        width: 100%;
        background: #000 !important;
        background-size: cover;
    } */
  .prod-video {
    max-height: 100%;
    height: 100%;
    align-self: center;
  }

  .slider-swiper-button-next {
    background-image: url("/assets/imagens/arrow_next_gray.svg");
  }

  @media only screen and (max-width: 640px) {
    .produtos-wrapper .produto-item {
      min-width: 100%;
    }
    .seccao main .main-inner-wrapper .main-coluna-100p {
      max-width: calc(100vw - 100px) !important;
    }
    .produtos-wrapper .links {
      width: 100%;
    }
    .seccao.header-fixo main.listagem-produtos-com-footer {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .produtos-wrapper.swiper-wrapper {
      width: 100%;
    }
    .produtos-swiper-button-prev {
      left: 5px;
    }
    .produtos-swiper-button-next {
      right: 5px;
    }

    .prod-video {
      max-height: 100%;
      height: auto;
      width: 100%;
      align-self: center;
    }
  }
</style>
